var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"input",staticClass:"gl-input__wrapper",class:{ 'gl-input__wrapper--full': _vm.fullWidth },attrs:{"name":_vm.name || _vm.label,"rules":_vm.rules,"tag":"div","vid":_vm.vid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
var reset = ref.reset;
return [(_vm.label || _vm.maxLength)?_c('div',{staticClass:"flex space-between"},[_c('label',{staticClass:"gl-input__label",class:{ 'gl-input__label--invalid': errors[0] },attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label || _vm.name)+" ")]),(_vm.rules.includes('max') && _vm.maxLength)?_c('div',{staticClass:"gl-input__label gl-modal__title--capitalize"},[_vm._v(" "+_vm._s(_vm.value.length)+" / "+_vm._s(_vm.maxLength)+" Symbols ")]):_vm._e()]):_vm._e(),_c('input',{staticClass:"gl-input__input",class:[
      { 'gl-input__input--invalid': errors[0] },
      { 'gl-input__input--light': _vm.isLight },
      { 'gl-input__input--light-gray': _vm.lightGray } ],style:(("height: " + _vm.height + _vm.units + "; margin-bottom: " + _vm.extraMarginBottom)),attrs:{"id":_vm.name,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled,"max":_vm.max,"min":_vm.min,"oninput":_vm.oninput,"placeholder":_vm.placeholder,"type":_vm.innerType},domProps:{"value":_vm.value},on:{"blur":function($event){return _vm.$emit('blur')},"change":function($event){return _vm.$emit('change', $event.target.value)},"focus":function($event){return _vm.$emit('focus')},"input":_vm.handleInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.$emit('enter', $event.target.value)}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.value && _vm.clearable)?_c('gl-icon',{staticClass:"gl-search-box__clear-icon",class:[{ 'gl-search-box__clear-icon--labeled': _vm.label || _vm.name }],attrs:{"height":24,"name":"clear-large-dark","width":24},on:{"click":_vm.clearValue}}):_vm._e()],1),_c('div',{staticClass:"gl-input__input--password-icon"},[(_vm.hideControl)?_c('gl-icon',{staticClass:"pointer",attrs:{"height":24,"name":_vm.hide ? 'hide' : 'unhide',"width":24},on:{"click":_vm.toggleVisible}}):_vm._e()],1),(errors[0])?_c('span',_vm._b({staticClass:"gl-input__error",class:{ 'gl-input__error--block': _vm.isBlockErrorStyle }},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }