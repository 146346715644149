<template>
  <div>
    <div id="g_id_onload" />
    <div id="g_id_signin" />
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.initGoogleSignIn()
    }, 600)
  },
  methods: {
    initGoogleSignIn() {
      window.handleCredentialResponse = (response) => {
        const credential = response.credential
        this.$emit('googleAuth', credential)
      }

      window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_CLIENT_ID_FOR_GOOGLE,
        callback: window.handleCredentialResponse,
        context: 'signin',
        ux_mode: 'popup',
      })

      window.google.accounts.id.renderButton(
        document.getElementById('g_id_signin'),
        {
          theme: 'filled_blue',
          size: 'medium',
          shape: 'pill',
          type: 'standard',
        }
      )

      window.google.accounts.id.prompt()
    },
  }
};
</script>

<style>
.login__google {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
