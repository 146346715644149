<template>
  <div class="login">
    <div class="login__form-logo mb-2">
      <SatoshiEyeLogo
        class="pointer"
        @click="$router.push({ name: 'landing-page' })"
      />
    </div>
    <div class="login__form">
      <!-- login -->
      <div
        v-if="!isResetPassword"
        class="login__form-wrapper"
      >
        <h2 class="login__title">
          Welcome back!
        </h2>
        <div class="login__sub-title">
          Enter your credentials to access your account
        </div>
        <gl-form
          ref="form"
          class="mb-2"
          full-submit
          ok-only
          submit-title="Log In"
          @submit="handleSubmit"
        >
          <gl-input
            v-model="email"
            autocomplete="email"
            class="login__input"
            is-block-error-style
            label="Email"
            name="Email"
            placeholder="email@example.com"
            rules="required|email"
          />
          <gl-input
            v-model="password"
            autocomplete="current-password"
            class="login__input"
            hide-control
            label="Password"
            name="Password"
            placeholder="Enter your current password..."
            rules="required"
            type="password"
          />
          <GlActionLink
            bold
            class="mt-4 mb-4 flex-end m-fs-14"
            link-text="Forgot password?"
            @click="isResetPassword = true"
          />
        </gl-form>
        <div
          v-if="getGoogleLoginFeatureFlag && !isResetPassword"
          class="login__google"
        >
          <GoogleLogin 
            @googleAuth="sendGoogleToken" 
          />
        </div>
        <GlActionLink
          bold
          class="mt-4 m-fs-14"
          label="Don’t have an account?"
          link-text="Sign Up"
          @click="$router.push('/register')"
        />
        <!-- <GlActionLink
          bold
          class="mt-4 m-fs-14"
          label="Any troubles?"
          link-text="Contact support"
          @click="contactSupport"
        /> -->
      </div>
      <!-- Reset Password -->
      <div
        v-if="isResetPassword"
        class="login__form-wrapper"
      >
        <h2 class="login__title mt-4">
          Forgot Password?
        </h2>
        <div v-if="isPasswordReseted">
          <div class="login__sub-title">
            No worries, we’ve sent reset instructions
            <strong class="link display-inline">{{ email }}</strong>
          </div>
          <button
            class="gl-button gl-button--dark gl-form__button gl-button--padder full-submit"
            @click="
              isPasswordReseted = false
              isResetPassword = false
            "
          >
            Back to Log In
          </button>
          <div class="text-center link bold-600 mt-4">
            <div
              v-if="isTimerEnd"
              @click="onResetSubmit"
            >
              Resend email
            </div>
            <GlTimer
              v-else
              :timer-count="60"
              @end-timer="isTimerEnd = true"
            />
          </div>
        </div>
        <div v-else>
          <div class="login__sub-title">
            No worries, we’ll send you reset instructions.
          </div>
          <gl-form
            ref="resetForm"
            class="mb-2"
            full-submit
            ok-only
            submit-title="Reset Password"
            @submit="onResetSubmit"
          >
            <gl-input
              v-model="email"
              autocomplete="email"
              class="login__input"
              label="Email"
              name="email"
              placeholder="email@example.com"
              rules="required|email"
            />
          </gl-form>
          <GlActionLink
            bold
            class="mt-4 m-fs-14"
            label="Back to"
            link-text="Log In"
            @click="isResetPassword = false"
          />
          <!-- <GlActionLink
          bold
          class="mt-4 m-fs-14"
          label="Any troubles?"
          link-text="Contact support"
          @click="contactSupport"
        /> -->
        </div>
      </div>
    </div>
    <div class="login__bottom">
      SATOSHI EYE © {{ getFullYear }} ALL RIGHTS RESERVED
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// Components
import SatoshiEyeLogo from '@/assets/svg/header/SatoshiEyeLogo.svg?inline'
import GlForm from '@/components/gl-form'
import GlInput from '@/components/gl-input'
import GlActionLink from '@/components/gl-action-link'
import GlTimer from '@/components/gl-timer'
import GoogleLogin from './components/GoogleLogin'
// utils
import { contactSatoshieyeSupport } from '@/utils/go-to-route'

export default {
  components: {
    GlForm,
    GlInput,
    SatoshiEyeLogo,
    GlActionLink,
    GlTimer,
    GoogleLogin,
  },
  data() {
    return {
      email: '',
      password: '',
      isPasswordReseted: false,
      isResetPassword: false,
      isTimerEnd: false,
    }
  },
  computed: {
    ...mapGetters('analytics', ['getGoogleLoginFeatureFlag']),
    getFullYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    ...mapMutations({
      SET_USER_TOKEN: 'user/SET_USER_TOKEN',
      SET_USER_ID: 'user/SET_USER_ID',
      REMOVE_USER_TOKEN: 'user/REMOVE_USER_TOKEN',
      SET_USER_REFRESH_TOKEN: 'user/SET_USER_REFRESH_TOKEN',
      REMOVE_USER_ID: 'user/REMOVE_USER_ID',
    }),
    ...mapActions('user', ['login', 'getMe', 'forgotPassword']),
    contactSatoshieyeSupport,
    async handleSubmit() {
      await this.clearAuthData()
      await this.login({ email: this.email.toLowerCase(), password: this.password })
        .then(({ data, success }) => {
          if (success) {
            this.SET_USER_TOKEN(data)
            this.SET_USER_REFRESH_TOKEN(data)
            this.SET_USER_ID(data)
            this.getMe()
            if (localStorage.getItem('to.fullPath')) {
              this.$router.push(localStorage.getItem('to.fullPath'))
            } else if(localStorage.getItem('promocode')) {
              this.$router.push({ name: 'payment' })
            } else {
              this.$router.push({ name: 'source-of-funds' })
            }
          } else {
            this.$refs.form.setErrors({
              Email: data.message,
            })
            this.$refs.form.setErrors({
              Password: ' ',
            })
          }
        })
        .catch(({ response: { data } }) => {
          this.$refs.form.setErrors({
            Email: data.data.message,
          })
          this.$refs.form.setErrors({
            Password: ' ',
          })
        })
    },
    async onResetSubmit() {
      if (this.email) {
        this.forgotPassword({ email: this.email.toLowerCase() })
          .then(({ success, data }) => {
            this.isTimerEnd = false
            if (success) {
              this.isPasswordReseted = true
              this.$toast.add({
                severity: 'success',
                summary: 'Success Message',
                detail: `We’ve sent you password restoration link to ${this.email.toLowerCase()}`,
                life: 3000,
              })
            } else {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data,
                life: 3000,
              })
            }
          })
      }
    },
    clearAuthData() {
      this.REMOVE_USER_TOKEN()
      this.REMOVE_USER_ID()
    },
    contactSupport() {
      this.contactSatoshieyeSupport()
    },
    async sendGoogleToken(token) {
      await this.clearAuthData()
      await this.login({ token })
        .then(({ data, success }) => {
          if (success) {
            this.SET_USER_TOKEN(data)
            this.SET_USER_REFRESH_TOKEN(data)
            this.SET_USER_ID(data)
            this.getMe()
            if (localStorage.getItem('to.fullPath')) {
              this.$router.push(localStorage.getItem('to.fullPath'))
            } else if(localStorage.getItem('promocode')) {
              this.$router.push({ name: 'payment' })
            } else {
              this.$router.push({ name: 'source-of-funds' })
            }
          } else {
            this.$refs.form.setErrors({
              Email: data.message,
            })
            this.$refs.form.setErrors({
              Password: ' ',
            })
          }
        })
        .catch(({ response: { data } }) => {
          this.$refs.form.setErrors({
            Email: data.data.message,
          })
          this.$refs.form.setErrors({
            Password: ' ',
          })
        })
    },
  },
}
</script>

<style>
.login__google {
  padding-top: 16px;
}
</style>