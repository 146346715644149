<template>
  <div>
    {{ timerCountInner }}
  </div>
</template>

<script>
export default {
  name: 'GlTimer',
  props: {
    timerCount: {
      type: Number,
      required: false,
      default: 20,
    },
  },
   data() {
    return {
      timerCountInner: this.timerCount,
    };
  },
  watch: {
    timerCountInner: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCountInner--
          }, 1000)
        } else {
          this.$emit("end-timer")
        }
      },
      immediate: true,
    },
  },
}
</script>
